const config = {
    apiBaseUrl: "http://13.126.102.54",

};

export default config;



// http://localhost:2001
// http://13.126.102.54:1010





